import React from "react";
import { css, Global } from "@emotion/core";
import { Link } from "gatsby";
import MediaQuery from "react-responsive";
import { Helmet } from "react-helmet";

export default function Error404Page() {
  const commonLinkStyles = css`
    padding: 32px;
    border-radius: 30px;
    background-color: white;
    text-decoration: none;
    color: rgb(20, 33, 61);
  `;
  const colorWhite = css`
    color: white;
  `;

  return (
    <div>
      <Helmet>
        <title>Mahariel Rosario- 404</title>
      </Helmet>
      <Global
        styles={css`
          body {
            margin: 0;
            background-color: rgba(20, 33, 61, 1);
          }
          @media (min-width: 1450px) {
            body {
              background: rgb(252, 163, 17);
              background: radial-gradient(
                circle at left center,
                rgba(252, 163, 17, 1) 50%,
                rgba(20, 33, 61, 1) 50%
              );
            }
          }
        `}
      />

      <MediaQuery minWidth={320} maxWidth={1023}>
        <div
          css={css`
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          `}
        >
          <h1
            css={css`
              color: #fca311;
              font-size: 6rem;
              text-align: center;
              margin-bottom: 0;
            `}
          >
            404
          </h1>
          <p
            css={css`
              ${colorWhite}
              font-size: 1.5rem;
              text-align: center;
            `}
          >
            The page you requested was not found
          </p>
          <Link
            to="/"
            css={css`
              ${commonLinkStyles};
              font-size: 1.5rem;
            `}
          >
            Visit Home
          </Link>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={1024} maxWidth={1449}>
        <div
          css={css`
            height: 100vh;
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 52%;
            `}
          >
            <h1
              css={css`
                margin: 0;
                font-size: 20rem;
                ${colorWhite}
              `}
            >
              404
            </h1>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 48%;
            `}
          >
            <p
              css={css`
                font-size: 1.9rem;
                ${colorWhite}
              `}
            >
              The page you requested was not found
            </p>
            <Link
              to="/"
              css={css`
                ${commonLinkStyles};
                font-size: 1.7rem;
              `}
            >
              Visit Home
            </Link>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery minWidth={1450}>
        <div
          css={css`
            height: 100vh;
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 52%;
            `}
          >
            <h1
              css={css`
                margin: 0;
                font-size: 25rem;
                ${colorWhite}
              `}
            >
              404
            </h1>
            <p
              css={css`
                font-size: 2.5rem;
                text-align: center;
                ${colorWhite}
              `}
            >
              The page you requested was not found
            </p>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 48%;
            `}
          >
            <Link
              to="/"
              css={css`
                ${commonLinkStyles};
                font-size: 2rem;
              `}
            >
              Visit Home
            </Link>
          </div>
        </div>
      </MediaQuery>
    </div>
  );
}
